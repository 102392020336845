<template>
  <div class="body_hdgz">
    <div class="title">一、邀请奖励说明</div>
    <div class="title1">1、VIP卡赠送规则：</div>
    <div>① <span class="biaozhu"> 首次邀请</span>，完成分享即可获赠<span class="biaozhu">3日VIP体验卡</span></div>
    <div>② 每成功邀请<span class="biaozhu">1位女士</span>，即可获赠<span class="biaozhu"> 1日VIP体验卡，无上限</span>
    </div>
    <div>③ 累计邀请<span class="biaozhu">10位男士</span>，一次性赠送<span class="biaozhu">10日VIP体验卡</span></div>
    <div>④ 累计邀请<span class="biaozhu">50位男士</span>，一次性赠送<span class="biaozhu">25日VIP体验卡</span></div>
    <div>⑤ 累计邀请<span class="biaozhu">100位男士</span>，一次性赠送<span class="biaozhu">60日VIP体验卡</span></div>
    <div>⑥ 累计邀请100位男士后，<span class="biaozhu">每多邀请100位男士</span>，均可获赠<span
        class="biaozhu">120日VIP体验卡</span></div>
    <div>⑦ VIP卡均为自动发放，时长可累加</div>
    <div class="title1">2、现金分成规则：</div>
    <div>① 受邀女士，产生解锁收益时，您将得到她<span class="biaozhu"> 单笔收益的2元现金</span>分成； （收益特指：解锁联系方式的收益）
    </div>
    <div>② 受邀女士，成功邀请其她女用户，则第三人产生解锁收益时，您将得到她<span class="biaozhu">单笔收益的1元现金</span>分成；
      （收益特指：解锁联系方式的收益）
    </div>
    <div>③ 受邀男士，每次购买VIP时，您将得到<span class="biaozhu">40%的相应VIP套餐的现金分成</span>；</div>
    <div>·注：苹果用户购买VIP时，在套餐70%基础上分红，因为苹果有30%的强制抽成</div>
    <div class="title1">3、提现金额达标额外现金奖励：</div>
    <div>① 活动期间，由活动产生的现金收益，提现时享受平台活跃用户额外现金激励，额外激励会与提现金额一并到账</div>
    <div>② 额外奖励发放标准</div>
    <div style="text-align: center;">
      <img class="imagestyle" src="@/assets/image/txsm_biaoge.png"/>
    </div>
    <div class="title1">4、奖励如何获得：</div>
    <div>① 被邀请女士注册后<span class="biaozhu"> 完成认证且</span>被判定为优质，则视为有效用户，条件达成后即为你发放<span
        class="biaozhu">10元奖励</span>，若3天内该女士被判为不优质用户，则收回该奖励；
    </div>
    <div>② 被邀请男士<span class="biaozhu">注册后视为有效用户</span>，充值条件达成后系统将自动为你发放奖励；</div>
    <div>③ 同一设备、IP等情况视作同一人；</div>
    <div>④ 一经发现异常邀请行为，平台将扣除相应奖励，情节严重者将被封号；</div>

    <div class="title1">5、现金奖励如何提取：</div>
    <div>① 获得奖励或分成后的72小时内，提供收益的用户没有异常即可提取；</div>
    <div>② 若提供收益的用户在此期间有异常，奖励将被扣除；</div>
    <div>③ 提现时因三方支付平台要求，将扣除5%的手续费</div>
    <div class="title">二、其他说明</div>
    <div>
      请务必邀请可靠的朋友，存在以下情况之一的，平台将视情节严重作出包括但不限于扣罚奖励、邀请奖励降权、封禁邀请权限、封禁账号的处罚：
    </div>
    <div>① 恶意邀请大量无效用户的；</div>
    <div>② 受邀女性存在较多非精准、不优质的；</div>
    <div> ③ 受邀男士达标率极低的；</div>
    <div>④ 受邀男士支付率极低的。</div>
  </div>
</template>

<script setup>

</script>


<style>
.body_hdgz {
  font-size: 28px;
  color: #616065;
  font-family: PingFang-SC-Bold, PingFang-SC;
  line-height: 48px;
  font-weight: 400;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #FFFFFF;
  height: auto;
}

.title {
  font-size: 32px;
  font-weight: bold;
  color: #0C0B24;
  line-height: 45px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.title1 {
  font-size: 28px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #302E35;
  line-height: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.biaozhu {
  color: #CA6E05;
}

.imagestyle {
  width: 702px;
  height: 194px;
  margin-top: 19px;
  margin-bottom: 19px;
  text-align: center;
}
</style>